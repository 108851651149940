@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body{
    background: #333;
    color: #fff;
	margin:0;
	padding:0;
	min-height: 100%;
	font-size: 10px;
	font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html{
    min-height: 100%;
    overflow-x: hidden; 
    overflow-y: auto;
    overflow: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
button,
svg {
  display: inline-block;
  vertical-align: middle;
}
a{
	text-decoration: none;
}
h1,h2,h3,h4,h5,h6,p{
	font-size: 12px;
	font-weight: normal;
	margin: 0;
	padding: 0;
	color: #cecece;
}
ul{
	list-style: none;
	margin: 0;
	padding: 0;
}
::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
::-webkit-input-placeholder{
	color: #151515;
	opacity: 1;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
:-ms-input-placeholder{
	color: #151515;
	opacity: 1;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
::placeholder{
	color: #151515;
	opacity: 1;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
:-ms-input-placeholder{
	color: #151515;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
::-ms-input-placeholder{
	color: #151515;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
.App {
  text-align: center;
}
.container{
	background-color: #333;
}

/* HEADER MOBILE */

.header_devices{
	background-color: #00000b;
    display: -webkit-flex;
    display: flex;
	-webkit-align-items: center;
	        align-items: center;
	height: 60px;
    padding: 5px;
    position: fixed;
    bottom: 0;
	width: calc(100% - 10px);
	z-index: 1000;
	box-shadow: 0px -10px 30px 0px rgba(0,0,0,0.5);
}
.logo a{
	display: -webkit-flex;
	display: flex;
}
.logo img{
	height: 45px;
	padding: 10px 5px;
	padding-bottom: 0;
}
.toolbar{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 60px;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}
.toolbar h1{
	font-size: 10px;
	margin-top: 4px;
}
.header_icon{
	height: 30px;
	width: 30px;
}
.open_filters{
	padding: 5px;
}
.menu_openers{
	display: -webkit-flex;
	display: flex;
}
.open_search{
	padding: 5px;
}
.open_menu{
	padding: 5px;
}

/* MENU */

.menu_container{
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	z-index: 99999;
}
.overlay{
	-webkit-flex: 1 1;
	        flex: 1 1;
	background-color: rgba(0, 0, 0, 0.8);
}
#menu{
	width: 250px;
	height: 100%;
	background-color: #00000b;
	box-sizing: border-box;
}
.menu_top{
	display: -webkit-flex;
	display: flex;
	padding: 5px;
}
.menu_middle h1{
	padding: 10px 5px;
	margin: 0 5px;
    font-size: 10px;
    text-align: left;
    background-color: #00000b;
}
.menu_domsub_selection{
	display: -webkit-flex;
	display: flex;
    margin: 0 5px;
}
.menu_domsub_btn{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex: 1 1;
	        flex: 1 1;
	padding: 10px;
	background: #1b1b1b;
    background: linear-gradient(0deg, #151515 0%, #212121 100%);
	color: #ececec;
	border: 1px solid #00000b;
	margin: 5px;
	transition: .1s ease;
	border-radius: 3px;
}
.selection_masters, .menu_master_btn:hover{
	border:	1px solid #009ce5;
}
.selection_slaves, .menu_slave_btn:hover{
	border:	1px solid #ca0034;
}
.free_account_btn{
	-webkit-flex: 1 1;
	        flex: 1 1;
}
.free_account_btn a{
    color: #ececec;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 15px;
    background: #009de6;
    background: linear-gradient(0deg, #0083bf 0%, #009de6 100%);
	font-size: 13px;
	border-radius: 3px;
}
.close_menu_btn{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
	margin-left: 5px;
}
.close_menu_icon{
	height: 25px;
    width: 25px;
	padding: 10px;
}
/* menu search */
.search_wrap{
	padding: 5px;
}
#search{
	display: -webkit-flex;
	display: flex;
	margin-top: 15px;
}
.search_input{
    padding: 12px;
    width: 75%;
    border: none;
    outline: none;
    background-color: #fff;
	color: #151515;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
}
#search_button{
    width: auto;
    border: none;
    outline: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
	background-color: #fff;
	width: 50px;
}
.search_menu_icon{
    width: 20px;
    height: 20px;
}
#search_suggestions{
	position: absolute;
	z-index: 50;
}
#search_suggestions li a{
	font-size: 12px;
    padding: 12px;
    background-color: #fff;
	text-align: left;
	display: block;
	color: #151515;
}

/* Menu categories */

.menu_list{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: calc(100% - 217px);
    color: #cecece;
    margin: 5px;
	overflow-y: scroll;
	background-color: #000008;
}
.menu_list li{
	font-size: 10px;
	text-align: left;
	color: #ececec;
}
.menu_list li a{
	color: #a5a5a5;
	font-size: 10px;
	padding: 12px 20px;
	text-align: left;
	display: block;
	background-color: #000008;
}
.category_heading{
	padding: 12px;
	background-color: #00000b;
    border-top: 1px solid #000019;
	border-bottom: 1px solid #000;
	border-left: 1px solid #0083c0;
}
.active_menulink{
	color: #ececec !important;
	padding-left: 25px!important;
	background-color: #00000b !important;
}

/* FILTERBOX MOBILE */

.filters_devices{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
	background-color: #00000b;
	padding: 5px;
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.filters_title_close{
	display: -webkit-flex;
	display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0 2px 5px 2px;
    -webkit-flex: 1 1;
            flex: 1 1;
}
.close_filters{
	display: -webkit-flex;
	display: flex;
}
.close_icon{
	width: 25px;
	height: 25px;
}
.filters_devices h2{
	font-size: 10px;
}
.sorters{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}
.sorter{
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 10px;
    border: none;
    outline: none;
    height: 25px;
    margin: 0 2px;
    background: rgb(41, 41, 41);
    background: linear-gradient(0deg, rgb(27, 27, 27) 0%, rgb(41, 41, 41) 100%);
    color: #cecece;
	font-family: 'Roboto', sans-serif;
	border-radius: 3px;
}
.sorter:hover{
	color: #fff;
}

/* MODELS */

.users{
	width:calc(100% - 5px);
}
.users:after{
	clear:both;
	content:" ";
	display:block;
}
.users>div{
	width:calc(50% - 5px);
	margin:5px 0 0 5px;
	float:left;
	position:relative;
}
.users>div>div{
	width:100%;
	padding-bottom: 56.25%;
}
.users>div>div>div{
	position:relative;
}
.users>div img{
	width:100%;
	display:block;
	background-color:#282828;
}
.users>div a{
	top:0;
	left:0;
	right:0;
}
.users>div .username,.users>div a{
	text-decoration:none;
	position:absolute;
	bottom:0;
}
.users>div .username{
    color: #fff;
    padding: 0;
    font-size: 10px;
	width: calc(100% - 10px);
    background-color: rgba(0, 0, 0, 0.6);
	display: -webkit-flex;
	display: flex;
	padding: 5px;
	font-family: 'Roboto', sans-serif;
}
.users>div .username .nick{
	-webkit-flex-basis: 80%;
	        flex-basis: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}
.users>div .username .age{
	-webkit-flex-basis: 20%;
	        flex-basis: 20%;
	text-align: right;
}

/* FOOTER */

footer{
	background-color: #00000b;
	line-height: 1.5;
}
.footer_centered{
	width: calc(95% - 10px);
    max-width: 1500px;
	margin: 0 auto;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 10px 5px;
	box-sizing: border-box;
}
.footer_left{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex-basis: 30%;
	        flex-basis: 30%;
	text-align: left;
}
.footer_left_top{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: baseline;
	        align-items: baseline;
	margin-bottom: 10px;
}
.footer_left_bottom h6{
	font-size: 11px;
	color: #a5a5a5;
	text-align: justify;
}
.footer_logo{
	height: 40px;
	margin-right: 10px;
}
.mobile_version{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: baseline;
	        align-items: baseline;
	color: #ececec;
	font-size: 12px;
	transition: .1s ease;
}
.fa-mobile-alt{
	margin-left: 5px;
	font-size: 11px;
}
.footer_right{
	display: -webkit-flex;
	display: flex;
	-webkit-flex: 1 1;
	        flex: 1 1;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.footer_right div{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-left: 25px;
	margin-top: 25px;
}
.footer_right h3{
	color: #ececec;
	font-size: 11px;
	margin-bottom: 10px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
.footer_right svg{
	font-size: 12px;
	margin-left: 5px;
}
.footer_right a{
	display: block;
	color: #a5a5a5;
	font-size: 11px;
	padding: 3px;
}
.footer_right a:hover{
	color: #ececec;
}

/* CONTACT */

.contact{
	text-align: left;
	padding: 10px;
	min-height: 100vh;
}
.contact h1{
	padding: 10px;
	color: #ececec;
	font-size: 16px;
}
.contact p{
	padding: 10px;
	color: #a5a5a5;
}

/* 404 */

.not_found{
	text-align: center;
	min-height: 100vh;
}
.not_found h1{
	color: #ececec;
	font-size: 16px;
	padding: 30px;
}

/* CHATROOM */

.close_chat_icon{
    display: -webkit-flex;
    display: flex;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    padding: 10px;
	background-color: rgba(0, 0, 0, 0.2);
	transition: .1s ease;
}
.close_chat_icon:hover{
	background-color: rgba(0, 0, 0, 0.25);
}
.close_chat_icon img{
	height: 20px;
    width: 20px;
}
iframe{
	border: none;
	padding: 0;
	margin: 0;
	background-color: #000;
}
.chatroom{
	background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}
.chatroom-contentwrap{
	padding-top: 56.25%;
	color: #ececec;
}
.chatroom-current-model{
	background-color: #1b1b1b;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	height: 37px;
}
.chatroom-current-model h1{
	padding: 10px;
	font-size: 14px;
	text-transform: uppercase;
	color: #cecece;
}
.full_screen_chat{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 16px;
    background-color: #151515;
}
.fa-expand{
	font-size: 14px;
	color: #cecece;
}
.chatroom-topnav{
    display: -webkit-flex;
    display: flex;
	background-color: #272727;
	padding: 10px 5px;
	-webkit-align-items: center;
	        align-items: center;
}
.chatroom-topnav-nowlivebox{
	background: #1b1b1b;
    background: linear-gradient(0deg, #151515 0%, #212121 100%);
    border: 1px solid #006501;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 5px;
    color: #cecece;
    border-radius: 5px;
}
.chatroom-topnav-btns{
	display: -webkit-flex;
	display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
}
.chatroom-topnav-btn{
	padding: 10px 5px;
	-webkit-flex: 1 1;
	        flex: 1 1;
	margin-left: 5px;
	font-size: 10px;
	background: #1b1b1b;
    background: linear-gradient(0deg, #151515 0%, #212121 100%);
	color: #cecece;
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: center;
            justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 5px;
	transition: .1s ease;
}
.chatroom-topnav-btn:hover{
	color: #ececec;
}
.free_chat{
    border: 1px solid rgba(0, 157, 230, 0.5);
	-webkit-flex: 2 1;
	        flex: 2 1;
}
.go_private{
	border: 1px solid rgba(202, 0, 52, 0.75);
	-webkit-flex: 3 1;
	        flex: 3 1;
}
.join{
	border: 1px solid rgba(0, 157, 230, 0.5);
}
.chatroom-info{
	padding: 10px;
	font-size: 12px;
	background-color: #2f2f2f;
	position: relative;
}
.chatroom-info div{
	display: -webkit-flex;
	display: flex;
	margin-bottom: 10px;
	color: #1f1f1f;
}
.chatroom-info h2{
	margin-right: 10px;
	color: #ececec;
	font-size: 11px;
}
.chatroom-info p{
	margin: 0;
	text-transform: capitalize;
	text-align: justify;
	font-size: 11px;
	color: #9a9a9a;
}
.recent_shot{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #272727;
    padding: 5px;
}
.recent_shot_gallerylink{
	display: block;
    background-color: #1b1b1b;
    padding: 5px;
    color: #cecece;
    font-size: 10px;
}
.chatroom-contentwrap h4{
	padding: 15px 5px 10px 5px;
	color: #9a9a9a;
	text-align: left;
	font-size: 14px;
}
.chatroom-profile-picture{
	width: 120px;
}
.highlight{
	color: #009de6;
}

/* DESKTOP HEADER */

header{
	background-color: #00000b;
}
.header_centered{
	width: 95%;
	max-width: 1500px;
	margin: 0 auto;
}
nav{
	background-color: #1b1b1b;
}
.nav_centered{
	width: 95%;
	max-width: 1500px;
	margin: 0 auto;
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.header__pc_top{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	padding: 10px 5px;
}
.header__pc_content_left{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}
.header__pc_logo a{
	display: -webkit-flex;
	display: flex;
}
.header__pc_heading_wrap{
	display: -webkit-flex;
	display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin-left: 10px;
}
.header__pc_buttons{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}
.header__pc_button_account{
    background: #ca0034;
    background: linear-gradient(0deg, #b5002f 0%, #ca0034 100%);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
	color: #ececec;
    height: 25px;
	width: 170px;
	border-radius: 3px;
}
.header__pc_button_account:hover{
	background: linear-gradient(0deg, #b5002f 0%, #b5002f 100%);
}
.header__pc_button_login{
	display: -webkit-flex;
	display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #ececec;
    height: 25px;
    width: 80px;
    margin-right: 5px;
    background: #009de6;
    background: linear-gradient(0deg, #008bcc 0%, #009de6 100%);
	border-radius: 3px;
}
.header__pc_button_login:hover{
    background: linear-gradient(0deg, #008bcc 0%, #008bcc 100%);
	color: #ececec;
}
.navlinks{
	display: -webkit-flex;
	display: flex;
	margin: 0 5px;
}
.navlink{
	padding: 10px 0;
	margin-right: 30px;
    color: #cecece;
	transition: .1s ease;
	border-bottom: 1px solid transparent;
}
.navlink:hover, .navlink_active{
	color: #ececec;
	border-bottom: 1px solid #009de6;
}
.nav_master_slave{
	display: -webkit-flex;
	display: flex;
}
.domsub_select{
    padding: 9px 0;
    color: #a5a5a5;
    font-size: 12px;
    border-bottom: 1px solid transparent;
    transition: .1s ease;
}
.sub_btn{
	margin-left: 20px;
	margin-right: 5px;
}
.master_on, .dom_btn:hover{
	color: #ececec;
	border-bottom: 2px solid #009de6;
}
.slave_on, .sub_btn:hover{
	color: #ececec;
	border-bottom: 2px solid #ca0034;
}

/* SIDEMENU DESKTOP */

#sidemenu__desktop{
	display: none;
	width: 220px;
	background-color: #1b1b1b;
	margin: 0 5px;
}
.sidemenu{
	height: 100%;
	color: #000;
	font-size: 20px;
}
.credits_box{
	margin-bottom: 10px;
}
.promotions{
	display: block;
	padding: 12px;
	background: #ca0034;
    background: linear-gradient(0deg, #b5002f 0%, #ca0034 100%);
	color: #ececec;
	font-size: 12px;
	margin: 5px;
	transition: .1s ease;
}
.promotions:hover{
	background: linear-gradient(0deg, #b5002f 0%, #b5002f 100%);
}
.fa-coins{
	font-size: 14px;
	margin-right: 5px;
}
.sidemenu_top_options{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}
.sidemenu_top_options a{
	display: -webkit-flex;
	display: flex;
	color: #a5a5a5;
	font-size: 11px;
	padding: 10px;
	text-align: left;
	transition: .1s ease;
}
.sidemenu_top_options a:hover{
	color: #ececec;
}
.sidemenu_top_options svg{
	font-size: 11px;
	margin-right: 5px;
}
#side_list{
	color: #ececec;
	font-size: 12px;
	text-align: left;
}
.side_list_title{
	padding: 10px;
}
#side_list li a{
	padding: 10px;
	display: block;
	color: #a5a5a5;
	font-size: 11px;
	transition: .1s ease;
}
#side_list li a:hover{
	color: #ececec;
}
.active_sidepage{
	color: #ececec !important;
}

/* user container heading */
.users_heading{
	text-align: left;
	padding: 10px 5px;
	font-size: 14px;
	text-transform: capitalize;
}

/* TEXT CONTENT */

#content{
	margin-bottom: 70px;
	line-height: 1.5;
}
#content h1{
    padding: 10px 0;
    font-size: 16px;
    text-align: left;
    color: #ececec;
}
#content p{
	padding: 5px 0;
	font-size: 12px;
	text-align: justify;
	color: #a5a5a5;
}
.text_content{
	box-sizing: border-box;
    padding: 10px;
}



/* media queries */

@media only screen and (min-width: 360px) {
	.logo img{
		height: 50px;
		padding: 5px;
		padding-bottom: 0;
	}
}

@media only screen and (min-width: 568px) {
	.users>div {
		width: calc(33.3333% - 5px);
	}
}

@media only screen and (min-width: 768px) {
	#menu{
		width: 300px;
	}
	.close_chat_icon{
		top: 10px;
		left: 10px;
	}
	.chatroom-topnav-nowlivebox{
		padding: 10px 30px;
	}
	.open_filters {
		padding: 5px 10px;
	}
	.open_search {
		padding: 5px 10px;
	}
	.open_menu {
		padding: 5px 10px;
	}
}

/* Desktop */

@media only screen and (min-width: 769px) {
	.users{
		min-height: 100vh;
	}
	.users>div {
		width: calc(50% - 5px);
	}
	.wrap_sidemenu_also{
		display: -webkit-flex;
		display: flex;
		width: 95%;
		margin: 0 auto;
		max-width: 1500px;
		padding: 10px 0;
	}
	#sidemenu__desktop{
		display: block;
	}
	.container {
		background-color: #333;
	}
	.logo_pc{
		height: 60px;
	}
	.search_wrap{
		padding: 0;
		margin-top: 5px;
	}
	.search_input{
		padding: 1px 0 0 10px;
		height: 29px;
		font-size: 12px;
		width: 100%;
		color: #151515;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	#search_button{
		background-color: #eaeaea;
		cursor: pointer;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	::-webkit-input-placeholder{
		color: #151515;
		opacity: 1;
		font-size: 12px;
	}
	:-ms-input-placeholder{
		color: #151515;
		opacity: 1;
		font-size: 12px;
	}
	::placeholder{
		color: #151515;
		opacity: 1;
		font-size: 12px;
	}
	:-ms-input-placeholder{
		color: #151515;
		font-size: 12px;
	}
	::-ms-input-placeholder{
		color: #151515;
		font-size: 12px;
	}
	.navfilters_flex{
		
	}
	.open_filters {
		padding: 0;
		cursor: pointer;
		border: 1px solid #333;
		margin: 5px;
	}
	.open_filters h1{
		font-size: 12px;
		padding: 10px;
		color: #a5a5a5;
		transition: .1s ease;
		text-align: left;
		border-radius: 3px;
	}
	.open_filters h1:hover{
		color: #ececec;
	}
	.header_icon {
		display: none;
	}
	.filters_devices{
		position: relative;
		width: calc(100% - 10px);
		padding: 0;
		background-color: transparent;
		border: 1px solid #333;
		margin: 5px;
	}
	.filters_title_close{
		margin: 0;
		padding: 7px 5px;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
	}
	.filters_title_close h2{
		display: none;
	}
	.close_icon {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.sorters{
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: unset;
		        align-items: unset;
	}
	.sorter{
		cursor: pointer;
		-webkit-flex: 1 1;
		        flex: 1 1;
		height: unset;
		font-size: 11px;
		padding: 10px;
		margin: 0;
		background: #1b1b1b;
		border: none;
		color: #a5a5a5;
		text-align: left;
		transition: .1s ease;
	}
	.sorter:hover{
		color: #ececec;
	}
	#search_suggestions li a{
		padding: 10px;
		background-color: #fff;
		color: #151515;
		border-top: 1px solid #eaeaea;
	}
	#search_suggestions li a:hover{
		background-color: #eaeaea;
	}
	.users>div .username{
		font-size: 11px;
	}
	#liveroom_container{
		width: 95%;
		margin: 0 auto;
		max-width: 1500px;
		padding: 10px 5px;
		box-sizing: border-box;
	}
	.users_wrapper{
		width: 100%;
	}
	.chatroom{
		position: relative;
		display: -webkit-flex;
		display: flex;
	}
	.chatroom-contentwrap{
		padding-top: 0;
	}
	.chatroom-contentwrap h4{
		display: none;
	}
	.contact{
		width: 100%;
	}
	.not_found{
		width: 100%;
		text-align: left;
	}
	.not_found h1{
		padding: 10px;
	}
	.center_chatmodels {
		width: 95%;
		max-width: 1500px;
		margin: 0 auto;
	}
	iframe{
		background-color: #1b1b1b;
	}
	.chatroom-info{
		background-color: #2b2b2b;
	}
	#content{
		margin-bottom: 10px;
	}
	.text_content{
		width: calc(95% - 10px);
		max-width: 1500px;
		margin: 0 auto;
		padding: 10px 5px;
	}
}

@media only screen and (min-width: 1024px) {
	.users>div {
		width: calc(33.3333% - 5px);
	}
	.search_input{
		width: 250px;
	}
}

@media only screen and (min-width: 1280px) {
	.users>div {
		width: calc(25% - 5px);
	}
}

@media only screen and (min-width: 1520px) {
	.users>div {
		width: calc(20% - 5px);
	}
}

@media only screen and (min-width: 1920px) {
	.users>div {
		width: calc(16.6666666667% - 5px);
	}
}

@media only screen and (min-width: 1921px) {
	.header_centered{
		width: 90%;
		max-width: unset;
	}
	.nav_centered{
		width: 90%;
		max-width: unset;
	}
	.wrap_sidemenu_also{
		width: 90%;
		max-width: unset;
	}
	#liveroom_container{
		width: 90%;
		max-width: unset;
	}
	.footer_centered{
		width: 90%;
		max-width: unset;
	}
	.center_chatmodels{
		width: 90%;
		max-width: unset;
	}
	.text_content{
		width: 90%;
		max-width: unset;
	}
}

@media only screen and (min-width: 2200px) {
	.users>div {
		width: calc(14.2857142857% - 5px);
	}
}

@media only screen and (min-width: 2560px) {
	.users>div {
		width: calc(12.5% - 5px);
	}
}

@media only screen and (min-width: 3000px) {
	.users>div {
		width: calc(11.1111111111% - 5px);
	}
}

@media only screen and (min-width: 3300px) {
	.users>div {
		width: calc(10% - 5px);
	}
}

@media only screen and (min-width: 3600px) {
	.users>div {
		width: calc(9.09090909091% - 5px);
	}
}
